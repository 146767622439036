/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const accessibilityPolicyUrl = `static/accessibility-policy`;
export const accessibilityPolicyUrlPreLogin = '/accessibility-policy';

const AccessibilityPolicy: React.FC = () => {
  const { i18n } = useTranslation();
  const lang = useMemo(() => i18n.language, [i18n.language]);
  const renderSwitch = (lng: string) => {
    switch (lng) {
      case 'en':
        return <AccessibilityPolicyEnglish />;
      case 'fi':
        return <AccessibilityPolicyFinnish />;
      case 'sv':
        return <AccessibilityPolicySwedish />;
      default:
        return <AccessibilityPolicyFinnish />;
    }
  };

  return <div className="finbb-container">{renderSwitch(lang)}</div>;
};

const AccessibilityPolicyEnglish: React.FC = () => {
  return (
    <>
      <h1 className="static-h1">Accessibility Statement</h1>
      <h4>Omabiopankki.fi portal, Finnish Biobank Cooperative – FINBB</h4>
      <p>
        This accessibility statement pertains to the Omabiopankki.fi service,
        and it was prepared / last updated on 22 September 2020. The Service is
        subject to the Act on the Provision of Digital Services that requires
        that public online services must be accessible.
      </p>
      <p>We have evaluated the accessibility of the service by ourselves.</p>
      <h4>Status of the accessibility of the digital service</h4>
      <p>
        Accessibility of our services and equal treatment of citizens in the
        best possible way. The service meets the accessibility requirements in
        part and we will make further development in 2020-21 to improve
        accessibility.
      </p>
      <p>
        The service includes content published in the service by users or other
        external parties that is not produced, funded or supervised by the
        service provider itself.
      </p>
      <p>
        The website contains texts defined by research organisations and links
        to third-party services, such as surveys and tests. Research
        organisations and said third parties are responsible for the content in
        these regards.
      </p>
      <h4>
        Did you notice an accessibility issue in our digital service? Tell us
        about it, and we will fix the issue to the best of our ability
      </h4>
      <div className="static-block">
        <div>By e-mail</div>
        <div>
          <a href="mailto:info@fingenious.fi">info@fingenious.fi</a>
        </div>
        <div>By other means</div>
        <div>FINBB, Ruukinkatu 2–4, Turku, Finland</div>
      </div>

      <h4>Supervisory authority</h4>
      <p>
        If you notice any accessibility issues on this site, you should first
        send feedback to us, i.e. the website administrator. It may take up to
        14 days to receive a reply. If you are not satisfied with the response
        you receive, or if you do not receive any response within two weeks,{' '}
        <a href="https://www.saavutettavuusvaatimukset.fi/oikeutesi/">
          you can file a notice with with the Regional State Administrative
          Agency for Southern Finland
        </a>
        . The website of the Regional State Administrative Agency for Southern
        Finland provides detailed instructions on how to file the notice and how
        the matter will be processed.
      </p>
      <h4>Contact details of the supervisory authority</h4>
      <div className="static-block">
        <div>Regional State Administrative Agency for Southern Finland</div>
        <div>Accessibility Control Unit</div>
        <div>
          <a href="https://www.saavutettavuusvaatimukset.fi">
            www.saavutettavuusvaatimukset.fi
          </a>
        </div>
        <div>saavutettavuus(at)avi.fi</div>
        <div>Switchboard +358 295 016 000</div>
      </div>
    </>
  );
};

const AccessibilityPolicyFinnish: React.FC = () => {
  return (
    <>
      <h1 className="static-h1">Saavutettavuusseloste</h1>
      <h4>Omabiopankki.fi -portaali, Biopankkien Osuuskunta Suomi - FINBB</h4>
      <p>
        Tämä saavutettavuusseloste koskee palvelua Omabiopankki.fi ja on
        laadittu / päivitetty 22.09.2020. Palvelua koskee laki digitaalisten
        palvelujen tarjoamisesta, jossa edellytetään, että julkisten
        verkkopalvelujen on oltava saavutettavia.
      </p>
      <p>Olemme arvioineet palvelun saavutettavuuden itse.</p>
      <h4>Digipalvelun saavutettavuuden tila</h4>
      <p>
        Palveluidemme saavutettavuus ja kansalaisten tasapuolinen kohtelu on
        meille erittäin tärkeää. Palvelu täyttää saavutettavuusvaatimukset
        osittain ja teemme 2020-21 jatkokehitystä saavutettavuuden
        parantamiseksi.
      </p>
      <p>
        Palveluun sisältyy käyttäjien tai muiden ulkopuolisten tahojen
        palvelussa julkaisemia sisältöjä, jotka eivät ole palveluntarjoajan
        itsensä tuottamia, rahoittamia tai valvomia:
      </p>
      <p>
        Sivusto sisältää tutkimusorganisaatioiden määrittelemiä tekstejä ja
        linkkejä kolmansien osapuolten palveluihin, kuten kyselytutkimuksiin ja
        testeihin. Tutkimusorganisaatiot ja mainitut kolmannet osapuolet
        vastaavat sisällöistä näiltä osin.
      </p>
      <h4>
        Huomasitko saavutettavuuspuutteen digipalvelussamme? Kerro se meille ja
        teemme parhaamme puutteen korjaamiseksi
      </h4>
      <div className="static-block">
        <div>Sähköpostilla</div>
        <div>
          <a href="mailto:info@fingenious.fi">info@fingenious.fi</a>
        </div>
        <div>Muilla tavoin</div>
        <div>FINBB, Ruukinkatu 2-4, Turku</div>
      </div>
      <h4>Valvontaviranomainen</h4>
      <p>
        Jos huomaat sivustolla saavutettavuusongelmia, anna ensin palautetta
        meille eli sivuston ylläpitäjälle. Vastauksessa voi mennä 14 päivää. Jos
        et ole tyytyväinen saamaasi vastaukseen tai et saa vastausta lainkaan
        kahden viikon aikana,{' '}
        <a href="https://www.saavutettavuusvaatimukset.fi/oikeutesi/">
          voit tehdä ilmoituksen Etelä-Suomen aluehallintovirastoon
        </a>
        . Etelä-Suomen aluehallintoviraston sivulla kerrotaan tarkasti, miten
        ilmoituksen voi tehdä ja miten asia käsitellään.
      </p>
      <h4>Valvontaviranomaisen yhteystiedot</h4>
      <div className="static-block">
        <div>Etelä-Suomen aluehallintovirasto</div>
        <div>Saavutettavuuden valvonnan yksikkö</div>
        <div>
          <a href="https://www.saavutettavuusvaatimukset.fi">
            www.saavutettavuusvaatimukset.fi
          </a>
        </div>
        <div>saavutettavuus(at)avi.fi</div>
        <div>Puhelinnumero, vaihde 0295 016 000</div>
      </div>
    </>
  );
};

const AccessibilityPolicySwedish: React.FC = () => {
  return (
    <>
      <h1 className="static-h1">Tillgänglighetsutlåtande</h1>
      <h4>Omabiopankki.fi-portalen, Biopankkien Osuuskunta Suomi - FINBB</h4>
      <p>
        Detta tillgänglighetsutlåtande gäller tjänsten Omabiopankki.fi och har
        uppgjorts/uppdaterats 22.9.2020. Lagen om tillhandahållande av digitala
        tjänster enligt vilken offentliga webbtjänster måste vara tillgängliga
        tillämpas på tjänsten.
      </p>
      <p>Vi har gjort vår egen bedömning av tjänstens tillgänglighet.</p>
      <h4>Den digitala tjänstens tillgänglighet</h4>
      <p>
        Tillgängligheten för våra tjänster och lika behandling av våra
        medborgare är mycket viktigt för oss. Tjänsten uppfyller delvis
        tillgänglighetskraven och vi kommer att fortsätta utveckla 2020-21 för
        att förbättra tillgängligheten.
      </p>
      <p>
        I tjänsten ingår innehåll som publicerats i tjänsten av användarna eller
        av övriga utomstående instanser. Innehållet har inte producerats,
        finansierats eller kontrollerats av tjänsteleverantören.
      </p>
      <p>
        Webbplatsen innehåller texter och länkar till tredje parts tjänster,
        såsom enkätundersökningar och tester, som lagts till av
        forskningsorganisationer. Forskningsorganisationerna och nämnda tredje
        parter ansvarar för innehållet i dessa.
      </p>
      <h4>
        Upptäckte du brister i tillgängligheten i vår digitala tjänst? Kontakta
        oss och informera oss om bristerna, så gör vi vårt bästa för att åtgärda
        dem.
      </h4>
      <div className="static-block">
        <div>Per e-post</div>
        <div>
          <a href="mailto:info@fingenious.fi">info@fingenious.fi</a>
        </div>
        <div>På annat sätt</div>
        <div>FINBB, Bruksgatan 2–4, Åbo</div>
      </div>
      <h4>Tillsynsmyndigheten</h4>
      <p>
        Om du upptäcker tillgänglighetsproblem på webbplatsen, börja med att ge
        respons till oss, dvs. till webbplatsens administratör. Det kan ta 14
        dagar innan du får svar. Om du inte är nöjd med svaret eller om du inte
        har fått något svar efter två veckor, kan du{' '}
        <a href="https://www.saavutettavuusvaatimukset.fi/oikeutesi/">
          lämna respons till Regionförvaltningsverket i Södra Finland
        </a>
        . På webbplatsen för Regionförvaltningsverket i Södra Finland beskrivs
        ingående hur du ska göra om du vill lämna respons och hur ärendet
        behandlas.
      </p>
      <h4>Tillsynsmyndighetens kontaktuppgifter</h4>
      <div className="static-block">
        <div>Regionförvaltningsverket i Södra Finland</div>
        <div>Enheten för tillgänglighetstillsyn</div>
        <div>
          <a href="https://www.saavutettavuusvaatimukset.fi">
            www.saavutettavuusvaatimukset.fi
          </a>
        </div>
        <div>saavutettavuus(at)avi.fi</div>
        <div>Telefonnummer, växel 0295 016 000</div>
      </div>
    </>
  );
};

export default AccessibilityPolicy;
